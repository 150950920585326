import Vue from "vue";
import VueRouter from "vue-router";
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

function finedRule(role) {
  try {
    let token = localStorage.token;
    let roles = jwt_decode(token).role.split(",");
    // console.log(role)
    //console.log(jwt_decode(token));
    // console.log(roles.includes(role));
    return roles.includes(role);
  } catch (e) {
    console.log(e);
  }
}

const routes = [
  // Admin Routes
  {
    path: "/",
    name: "Home",
    redirect: { name: "AdminHome" },
    component: () => import("@/views/AdminPanelViews.vue"),
    children: [
      {
        path: "/admin-panel-views",
        name: "AdminHome",
        component: () => import("../AdminPanel/AdminPages/AdminHome"),
      },
      {
        path: "/admin-panel-views/news",
        name: "News",
        component: () => import("../AdminPanel/AdminPages/NewsPage"),
      },
      {
        path: "/admin-panel-views/news/:id",
        name: "NewsId",
        component: () => import("../AdminPanel/AdminPages/NewsID"),
      },
      {
        path: "/admin-panel-views/newsToEdit/:id",
        name: "EditNews",
        component: () => import("../AdminPanel/AdminPageToEdit/EditNewsPage"),
      },
      {
        path: "/admin-panel-views/add-news",
        name: "AddNews",
        component: () => import("../AdminPanel/AdminPageToAdd/AddNewsPage"),
      },
      {
        path: "/admin-panel-views/export",
        name: "ExportsPage",
        component: () => import("../AdminPanel/AdminPages/ExportsPage"),
      },
      {
        path: "/admin-panel-views/add-export",
        name: "AddExport",
        component: () => import("../AdminPanel/AdminPageToAdd/AddExportPage"),
      },
      {
        path: "/admin-panel-views/export-details/:id",
        name: "ExportInfo",
        component: () => import("../AdminPanel/AdminPages/ExportID"),
      },
      {
        path: "/admin-panel-views/export-edit/:id",
        name: "ExportEdit",
        component: () => import("../AdminPanel/AdminPageToEdit/EditExportPage"),
      },

      {
        path: "/admin-panel-views/Documents&Manuscripts",
        name: "AdminDmPage",
        component: () => import("../AdminPanel/AdminPages/DmAdminPage"),
      },
      {
        path: "/admin-panel-views/Documents&Manuscripts/AddDm",
        name: "AddDmPage",
        component: () => import("../AdminPanel/AdminPageToAdd/AddDmPage"),
      },
      {
        path: "/admin-panel-views/Documents&Manuscripts/DmInfo",
        name: "DmDetailsAdminPage",
        component: () => import("../AdminPanel/AdminPages/DmDetailsAdminPage"),
      },
      {
        path: "/admin-panel-views/Documents&Manuscripts/DMAdminEdit",
        name: "DMAdminEdit",
        component: () => import("../AdminPanel/AdminPageToEdit/DMAdminEdit"),
      },
      {
        path: "/admin-panel-views/NjfAdmin",
        name: "NjfAdminPage",
        component: () => import("../AdminPanel/AdminPages/NajafAdminPage"),
      },
      {
        path: "/admin-panel-views/NjfAdmin/AddNjfPage",
        name: "AddNjfPage",
        component: () => import("../AdminPanel/AdminPageToAdd/AddNjfPage"),
      },
      {
        path: "/admin-panel-views/NjfAdmin/NjfEditPage",
        name: "NjfEditPage",
        component: () => import("../AdminPanel/AdminPageToEdit/NjfEditPage"),
      },
      {
        path: "/admin-panel-views/NjfAdmin/NjfAdminDetailsPage",
        name: "NjfAdminDetailsPage",
        component: () => import("../AdminPanel/AdminPages/NjfAdminDetailsPage"),
      },
      {
        path: "/admin-panel-views/NjfAdminLibrary",
        name: "NjfAdminLibrary",
        component: () => import("../AdminPanel/AdminPages/NjfAdminLibrary"),
      },
      {
        path: "/admin-panel-views/NjfAdminLibrary/NjfLibraryAdd",
        name: "NjfLibraryAdd",
        component: () => import("../AdminPanel/AdminPageToAdd/NjfLibraryAdd"),
      },
      {
        path: "/admin-panel-views/NjfAdminLibrary/NjfLibraryEdite",
        name: "NjfLibraryEdite",
        component: () =>
          import("../AdminPanel/AdminPageToEdit/NjfLibraryEdite"),
      },
      {
        path: "/admin-panel-views/MagazineAdminPage",
        name: "MagazineAdminPage",
        component: () => import("../AdminPanel/AdminPages/MagazineAdminPage"),
        redirect: { name: "MagazineAdminList" },
        children: [
          {
            path: "MagazineAdminList",
            name: "MagazineAdminList",
            component: () =>
              import("../AdminPanel/AdminPages/MagazineAdminList"),
          },
          {
            path: "MagazineSubjectPage",
            name: "MagazineSubjectPage",
            component: () =>
              import("../AdminPanel/AdminPages/MagazineSubjectPage"),
          },
          {
            path: "AddMagazinePage",
            name: "AddMagazinePage",
            component: () =>
              import("../AdminPanel/AdminPageToAdd/AddMagazinePage"),
          },
          {
            path: "AddMagazineSubjectPage",
            name: "AddMagazineSubjectPage",
            component: () =>
              import("../AdminPanel/AdminPageToAdd/AddMagazineSubjectPage"),
          },
          {
            path: "EditMagazinePage",
            name: "EditMagazinePage",
            component: () =>
              import("../AdminPanel/AdminPageToEdit/EditMagazinePage"),
          },
          {
            path: "EditMagazineSubjectPage",
            name: "EditMagazineSubjectPage",
            component: () =>
              import("../AdminPanel/AdminPageToEdit/EditMagazineSubjectPage"),
          },
          {
            path: "MagazineID",
            name: "MagazineID",
            component: () => import("../AdminPanel/AdminPages/MagazineID"),
          },
        ],
      },
      {
        path: "/admin-panel-views/CSPage",
        name: "CSPage",
        component: () => import("../AdminPanel/AdminPages/CSMainPage"),
        redirect: { name: "CsAdminPage" },
        children: [
          {
            path: "addCS",
            name: "addCS",
            component: () => import("../AdminPanel/AdminPageToAdd/AddCSPage"),
          },
          {
            path: "AddCsNews",
            name: "AddCsNews",
            component: () => import("../AdminPanel/AdminPageToAdd/AddCsNews"),
          },
          {
            path: "AddCsExport",
            name: "AddCsExport",
            component: () => import("../AdminPanel/AdminPageToAdd/AddCsExport"),
          },
          {
            path: "CsAdminPage",
            name: "CsAdminPage",
            component: () => import("../AdminPanel/AdminPages/CsAdminPage"),
          },
          {
            path: "EditCSPage",
            name: "EditCSPage",
            component: () => import("../AdminPanel/AdminPageToEdit/EditCSPage"),
          },
          {
            path: "EditCsNewsPage/:id",
            name: "EditCsNewsPage",
            component: () =>
              import("../AdminPanel/AdminPageToEdit/EditCsNewsPage"),
          },
          {
            path: "EditCsExport/:id",
            name: "EditCsExport",
            component: () =>
              import("../AdminPanel/AdminPageToEdit/EditCsExport"),
          },
        ],
      },
      {
        path: "/admin-panel-views/CsAdminMainDetails",
        name: "CsAdminMainDetails",
        component: () => import("../AdminPanel/AdminPages/CsAdminMainDetails"),
        redirect: { name: "CsAdminDetailsPage" },
        children: [
          {
            path: "CsAdminDetailsPage",
            name: "CsAdminDetailsPage",
            component: () =>
              import("../AdminPanel/AdminPages/CsAdminDetailsPage"),
          },
          {
            path: "CsGalleryAdminPage",
            name: "CsGalleryAdminPage",
            component: () =>
              import("../AdminPanel/AdminPages/CsGalleryAdminPage"),
          },
          {
            path: "CsYoutubeAdminPage",
            name: "CsYoutubeAdminPage",
            component: () =>
              import("../AdminPanel/AdminPages/CsYoutubeAdminPage"),
          },
          {
            path: "CsNewsAdminPage",
            name: "CsNewsAdminPage",
            component: () => import("../AdminPanel/AdminPages/CsNewsAdminPage"),
          },
          {
            path: "CsExportsAdminPage",
            name: "CsExportsAdminPage",
            component: () =>
              import("../AdminPanel/AdminPages/CsExportsAdminPage"),
          },
        ],
      },
      {
        path: "/admin-panel-views/settings",
        name: "Settings",
        redirect: { name: "SocialMedia" },
        component: () => import("../AdminPanel/SettingsPage/Settings"),
        children: [
          {
            path: "SocialMedia",
            name: "SocialMedia",
            component: () => import("../AdminPanel/SettingsPage/SocialMedia"),
          },
          {
            path: "AltosyAdminPage",
            name: "AltosyAdminPage",
            component: () =>
              import("../AdminPanel/SettingsPage/AltosyAdminPage"),
          },
          {
            path: "AboutUsAdminPage",
            name: "AboutUsAdminPage",
            component: () =>
              import("../AdminPanel/SettingsPage/AboutUsAdminPage"),
          },
          {
            path: "AdsAdminPage",
            name: "AdsAdminPage",
            component: () => import("../AdminPanel/SettingsPage/AdsAdminPage"),
          },
          {
            path: "friendsSite",
            name: "friendsSite",
            component: () => import("../AdminPanel/SettingsPage/friendsSite"),
          },
        ],
      },

      {
        path: "/admin-panel-views/users",
        name: "UsersPage",
        component: () => import("@/components/Users/UsersPage"),

        beforeEnter: (to, from, next) => {
          // console.log(finedRule("0"));
          if (finedRule("0") || finedRule("1")) {
            next();
          } else next({ name: "AdminHome" });
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      // console.log(localStorage.getItem("token") == null && from.path!= "login");
      if (localStorage.getItem("token") == null) {
        return next({ name: "login" });
      }
      to.path!= from.path ?  next() : console.log("same");
      // next();
    },
  },

  {
    path: "*",
    name: "NN",
    component: () => import("@/views/NotFoundPage"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LogIn.vue"),
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("token"));
      if (localStorage.getItem("token") != null) {
       return next({ name: "Home" });
      }

      to.path!= from.path ?  next() : console.log("same");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
