import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { Icon } from "@iconify/vue2";
import tooltipComponent from "@/components/tooltip-component";
import VueAxios from "vue-axios";
import axios from "axios";
import { VueEditor, Quill } from "vue2-editor";
import { createPinia, PiniaVuePlugin } from "pinia";
import jwt_decode from "jwt-decode";
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
// import LoadScript from 'vue-plugin-load-script';
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Icon", Icon);
Vue.config.productionTip = false;
// Vue.use(LoadScript);
// Vue.config.devtools = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
let token = localStorage.getItem("token");
let Url = "https://api.altusi.iq/";
// https://localhost:44364/
// https://192.168.81.27:5001/
const apiClint = axios.create({
  baseURL: Url + "api/",
  withCredentials: false,
  headers: {
    Authorization: "Bearer " + token,
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data"
  },
});
apiClint.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);

    if (
      error.response.status === 401 &&
      window.location.pathname !== "/notfound" &&
      window.location.pathname !== "/login"
    ) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
Vue.use(VueAxios, apiClint);
Vue.prototype.$axios = apiClint;
Vue.prototype.$appName = "مركز الشيخ الطوسي";
Vue.component("VueEditor", VueEditor);
Vue.component("tooltip-component", tooltipComponent);
let Font = Quill.import("formats/font");
const AlignStyle = Quill.import("attributors/style/align");
Font.whitelist = [
  "roboto-condensed",
  "beba",
  "roboto-condensed-2",
  "roboto-condensed-3",
  "font1",
  "font2",
  "font3",
];
Quill.register(Font, true);
Quill.register(AlignStyle, true);

Vue.mixin({
  methods: {
    finedRule: function (role) {
      try {
        let token = localStorage.token;
        let roles = jwt_decode(token).role.split(",");
        return roles.includes(role);
      } catch (e) {
        if (e.message.includes("Invalid token specified")) {
          localStorage.clear();
          router.push({name:"login"})
        }
        console.log(e);
      }
    },
  },
});

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
