<template>
<!--  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">-->
<!--    <template v-slot:activator="{ on, attrs }">-->
<!--      <v-icon-->
<!--          :large="large"-->
<!--          :small="small"-->
<!--          :color="color"-->
<!--          :dark="dark"-->
<!--          v-bind="attrs"-->
<!--          v-on="on"-->
<!--      >-->
<!--        {{icon}}-->
<!--      </v-icon>-->
<!--    </template>-->
<!--    <span>{{text}}</span>-->
<!--  </v-tooltip>-->
<v-container class="d-flex justify-center">
  <v-card width="1200" height="800" max-width="100%">
    <v-img contain width="400" height="400" src="https://api.altusi.iq//FreindsSite/hLCkcDfLx0uG0WuiLbmZfA.png" alt="img" />
    <img id="img" style="object-fit: contain" width="400" height="400" :src="url" alt="img" />
    <a href="#">
    <video  id="video" width="100%" height="100%"  controls preload="auto" oncontextmenu="return false;" controlsList="nodownload" data-setup='{"fluid": true}'>
      <source  src="http://restaurants.kf.iq:88/video/anime.mp4" type="video/mp4">
<!--      <source src="movie.ogg" type="video/ogg">-->
      Your browser does not support the video tag.
    </video>
    <v-card-actions >
      <v-btn>
        <v-icon>mdi-volume-high</v-icon>
      </v-btn>
      <v-btn>
        <v-icon>mdi-volume-minus</v-icon>
      </v-btn>
      <v-btn>
        <v-icon>mdi-volume-variant-off</v-icon>
      </v-btn>
      <v-btn @click="play">
        <v-icon>mdi-play</v-icon>
      </v-btn>
      <v-btn @click="pause">
        <v-icon>mdi-pause</v-icon>
      </v-btn>
    </v-card-actions>
    </a>

  </v-card>
<!--<v-btn id="button" @click="downloadImg">-->
<!--  Download-->
<!--</v-btn>-->


<!--  <a href="https://api.altusi.iq//FreindsSite/hLCkcDfLx0uG0WuiLbmZfA.png" download="https://api.altusi.iq//FreindsSite/hLCkcDfLx0uG0WuiLbmZfA.png">Download</a>-->
<!--  <a :href='url' download>Download image</a>-->
</v-container>
</template>

<script>
export default {
  data: () => ({
    url:"https://api.altusi.iq//FreindsSite/hLCkcDfLx0uG0WuiLbmZfA.png",
  }),
  name: "tooltip-component",
  props: ["bottom","top","left","right","small","large","icon","text","color","dark"],
  methods: {
    download() {
      let img = document.getElementById('img').src;
      const link = document.createElement('a');
      link.setAttribute('download', img.split('_').pop());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)

    },
    async downloadImg() {
      let type = this.url.split(/[#?]/)[0].split('.').pop().trim();


      let btnDownload = document.getElementById('button');
      let img = document.querySelector('img');
      btnDownload.addEventListener('click', () => {
        let imagePath = img.getAttribute('src');
        let fileName = "ee."+type;
        const link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = imagePath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
      });
    },
    play(){
      let vedio = document.getElementById("video");
      vedio.play();
    },
    pause(){
      let vedio = document.getElementById("video");
      vedio.pause();
    }
  }
}
</script>

<style scoped>
span{
  font-family: "ExpoArabicLight", monospace, sans-serif;
}
</style>
