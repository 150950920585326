import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    icons: {
        iconFont: "mdi", // default - only for display purposes
    },
    breakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 600,
            sm: 960,
            md: 1280,
            lg: 1920,
        },
    },
    // icons: {
    //   iconfont: "mdi",
    //   values: {},
    // },
    // lang: {
    //   current: 'ar',
    //   locales: { en },
    //   t: undefined as any,
    // },
    // rtl: false,
    theme: {
        dark: localStorage.getItem("dark") === "true",
        default: "light",
        disable: false,
        options: {
            cspNonce: undefined,
            customProperties: undefined,
            minifyTheme: undefined,
            themeCache: undefined,
        },
        themes: {
            light: {
                primary: "#1976D2",
                secondary: "#3D3C42",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FB8C00",
                main: "#21715b",
            },
            dark: {
                primary: "#2196F3",
                secondary: "#42032C",
                accent: "#FF4081",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FB8C00",
                main: "#21715b",
            },
        },
    },
});
