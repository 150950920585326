<template>
  <v-app class="app-main">
    <main>
      <router-view></router-view>
    </main>
    <SnackbarsApp />
  </v-app>
</template>

<script>
import { useMainStore as store } from "@/store/pinia";
import SnackbarsApp from "@/components/SnackbarsApp";

export default {
  name: "App",
  data: () => ({
    pinia: store(),
    show: true,
    rootTrackList: store().rootTrack,
    // rootTrackList:,
  }),
  components: { SnackbarsApp },

  mounted() {
    if (
      localStorage.token == null ||
      localStorage.token == undefined ||
      localStorage.token == ""
    ) {
      localStorage.clear();
      // this.$router.go();
    }
  },
};
</script>
<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.route-track {
  cursor: pointer;
}

@media (max-width: 450px) {
  .link-track-user {
    display: none;
  }
}

@media (max-width: 650px) {
  .map-footer {
    width: 100% !important;
  }
}

.v-list-item__content {
  font-family: "ExpoArabicLight", monospace, sans-serif;
  font-weight: 800;
}

/* Import the Quill styles you want */

.link-track-user {
  /*margin: 10px;*/
  /*margin-right: 20px;*/
  font-weight: bold;
  margin-right: 20px;
  font-family: "ExpoArabicLight", sans-serif;
}

.font {
  font-family: "ExpoArabicLight", "Helvetica Neue", Helvetica, sans-serif !important;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  /* margin-left: -1.5em; */
  /* margin-right: 0.3em; */
  /*text-align: right;*/
  margin: 0 !important;
}

@media (min-width: 1377px) {
  .footer-container {
    /*display: flex;*/
    padding-right: 250px !important;
    padding-left: 250px !important;
  }
}

.navbar-moved {
  transition: 1000ms ease-in-out;
  position: fixed;
  top: 0;
  background-color: #21715bd2;
  height: 75px;
  margin-top: 0;
  /*display: block;*/
}

.map-item p {
  margin: 0;
  font-family: "fontByMe4", "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 18px;
  font-weight: 800;
}

.map-item-sub {
  /*margin: 0;*/
  font-family: "fontByMe4", "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 18px;
  font-weight: bolder;
  color: black !important;
  margin-top: 17px;
}

.map {
  height: 250px !important;
}

.app-main {
  background-position: center center;
  background-size: contain;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-100%);
  }

  40% {
    display: block;
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateX(0%);
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-100%);
  }

  40% {
    display: block;
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateX(0%);
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-100%);
  }

  40% {
    display: block;
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
    transform: translateX(-100%);
  }

  40% {
    display: block;
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateX(0%);
  }
}

.slide-enter-active,
.slide {
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
}

.transition-move {
  transition: all 0.5s ease-in-out;
}

@font-face {
  font-family: "ExpoArabicLight";
  src: url("./assets/Fonts/ExpoArabicLight.ttf");
  /*font-weight: bolder;*/
  /* font-style: normal; */
}

@font-face {
  font-family: "DroidKufi-Regular";
  src: url("./assets/Fonts/DroidKufi-Regular.ttf");
  /*font-weight: bolder;*/
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe1";
  src: url("./assets/Fonts/font1.ttf");
  font-weight: bolder;
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe2";
  src: url("./assets/Fonts/font2.ttf");
}

@font-face {
  font-family: "fontByMe3";
  src: url("./assets/Fonts/font3.ttf");
  font-weight: bolder;
  /* font-style: normal; */
}

@font-face {
  font-family: "diwani";
  src: url("./assets/Fonts/diwani.ttf");
  /* font-weight: bolder; */
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe4";
  src: url("./assets/Fonts/font4.otf");
  /* font-weight: bolder; */
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe5";
  src: url("./assets/Fonts/font5.ttf");
  /* font-weight: bolder; */
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe6";
  src: url("./assets/Fonts/font6.ttf");
  /* font-weight: bolder; */
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe7";
  src: url("./assets/Fonts/font7.ttf");
  /* font-weight: bolder; */
  /* font-style: normal; */
}

@font-face {
  font-family: "fontByMe8";
  src: url("./assets/Fonts/font8.TTF");
  /* font-weight: bolder; */
  /* font-style: normal; */
}

.font-1 {
  font-family: "fontByMe1", "Helvetica Neue", Helvetica, sans-serif !important;
}

.font-2 {
  font-family: "fontByMe2", "Helvetica Neue", Helvetica, sans-serif !important;
}

.font-3 {
  font-family: "fontByMe3", "Helvetica Neue", Helvetica, sans-serif !important;
}

.font-4 {
  font-family: "fontByMe4", "Helvetica Neue", Helvetica, sans-serif !important;
}

.font-5 {
  font-family: "fontByMe5", "Helvetica Neue", Helvetica, sans-serif !important;
}

.font-6 {
  font-family: "fontByMe6", "Helvetica Neue", Helvetica, sans-serif !important;
}

.font-diwani {
  font-family: "diwani", "Helvetica Neue", Helvetica, sans-serif !important;
}

.editor div .ql-editor {
  overflow-y: scroll;
  max-height: 500px;
}

/*article  .content .ql-editor{*/
/*  overflow: !important;*/
/*  height: 100%;*/
/*}*/
</style>
<style lang="scss">
.ql-editor p,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  font-family: "ExpoArabicLight", "Helvetica Neue", Helvetica, sans-serif !important;
  //text-align: right ;
}

$font-name-1: "Roboto";
$font-name-2: "ExpoArabicLight";
$font-name-3: "fontByMe4";
$font-name-4: "fontByMe2";
$font-name-5: "fontByMe1";
$font-name-6: "fontByMe3";
$font-name-7: "fontByMe6";
$font-name-8: "fontByMe5";

#quill-editor {
  font-family: $font-name-2;
  font-size: 14px;
  height: 200px;
}

.ql-toolbar {
  .ql-picker.ql-font {
    width: 130px;
  }

  .ql-picker.ql-font .ql-picker-label::before,
  .ql-picker.ql-font .ql-picker-item::before {
    content: "الخط";
    font-family: $font-name-1, serif !important;
  }

  .ql-picker.ql-font span[data-value="roboto-condensed"]::before {
    content: "الخط";
    font-family: $font-name-4, cursive !important;
  }

  .ql-picker.ql-font span[data-value="roboto-condensed-3"]::before {
    content: "الخط";
    font-family: $font-name-5, cursive !important;
  }

  .ql-picker.ql-font span[data-value="roboto-condensed-2"]::before {
    content: "الخط الاول";
    font-family: $font-name-2, sans-serif !important;
  }

  .ql-picker.ql-font span[data-value="beba"]::before {
    content: "الخط";
    font-family: $font-name-3, cursive !important;
    font-weight: 800;
  }

  .ql-picker.ql-font span[data-value="font1"]::before {
    content: "الخط";
    font-family: $font-name-6, cursive !important;
    font-weight: 800;
  }

  .ql-picker.ql-font span[data-value="font2"]::before {
    content: "الخط";
    font-family: $font-name-7, cursive !important;
    font-weight: 800;
  }

  .ql-picker.ql-font span[data-value="font3"]::before {
    content: "الخط";
    font-family: $font-name-8, cursive !important;
    font-weight: 800;
  }
}

.ql-font-beba {
  font-family: $font-name-3, cursive !important;
}

.ql-font-roboto-condensed {
  font-family: $font-name-4, sans-serif !important;
}

.ql-font-roboto-condensed-2 {
  font-family: $font-name-2, sans-serif !important;
}

.ql-font-roboto-condensed-3 {
  font-family: $font-name-5, sans-serif !important;
}

.ql-font-roboto {
  font-family: $font-name-1, sans-serif !important;
}

.ql-font-font1 {
  font-family: $font-name-6, sans-serif !important;
}

.ql-font-font2 {
  font-family: $font-name-7, sans-serif !important;
}

.ql-font-font3 {
  font-family: $font-name-8, sans-serif !important;
}

//.ql-font-fontByMe4 {
//  font-family: $font-name-4, sans-serif !important;
//}
</style>
