<template>
  <v-snackbar
      :timeout="6000"
      multi-line
      v-model="pinia.snackbar.state"
      elevation="10"
      min-height="60"
      right
      :color="type ? 'primary' : 'error'"
      bottom
      class="f-flex align-content-space-between text-font-ar"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="white"
          text
          class="white--text text-font-ar"
          v-bind="attrs"
          @click="pinia.snackbar.state = false"
      >
        اغلاق
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { useMainStore as store } from "@/store/pinia";
export default {
  name: "SnackbarsApp",
  data: () => ({
        pinia:store()
      }

  ),
  computed: {
    state() {
      // this.get(store().snackbar.state);
      // return store().snackbar.state ? true : false;
      return store().snackbar.state;
    },
    text() {
      return store().snackbar.text;
    },
    type() {
      return store().snackbar.type;
    },
  },
}
</script>

<style scoped>
.text-font-ar{
  font-family: "fontByMe2", "Helvetica Neue", Helvetica, sans-serif !important;
}
</style>
