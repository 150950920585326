import { defineStore } from "pinia";
export const useMainStore = defineStore("main", {
  // a function that returns a fresh state
  state: () => ({
    username: localStorage.userName,
    rules: localStorage.roles,
    navBarItems: [
      { name: "الرئيسية", link: { name: "home" }, index: 0 },
      {
        name: "أخبار المركز",
        link: { name: "NewsView" },
        index: 1,
      },
      {
        name: "إصداراتنا",
        link: { name: "ExportView" },
        index: 2,
        department: [
          // { title: "جميع الاصدارات", value: null },
          { title: "الفقه", value: 99 },
          { title: "أصول الفقه", value: 1 },
          { title: "اللغة", value: 2 },
          { title: "الرجال", value: 3 },
          { title: "دراية الحديث", value: 4 },
          { title: "فهرس", value: 5 },
        ],
      },
      {
        name: "المؤتمرات و الندوات",
        link: { name: "CsPublicPage" },
        index: 3,
      },
      {
        name: "المجلة",
        link: { name: "Magazine" },
        index: 4,
      },
      { name: "الشيخ الطوسي", link: { name: "ShakeTosyPage" }, index: 5 },
      {
        name: "النجف الأشرف",
        link: { name: "Najaf" },
        index: 6,
        department: [
          { title: "مرقد الإمام علي (ع)", value: 1 },
          { title: "تاريخ المدينة", value: 5 },
          { title: "الحركة العلمية", value: 2 },
          { title: "معالم و مساجد", value: 3 },
          { title: "أعلام", value: 4 },
          { title: "المكتبة المخصصة", value: 99, link: { name: "library" } },
        ],
      },
      {
        name: "وثائق ومخطوطات",
        link: { name: "DocumentsManuscripts" },
        index: 7,
        department: [
          { title: "المخطوطات", value: 99 },
          { title: "الاجازات", value: 1 },
          { title: "المراسلات", value: 2 },
          { title: "فوائد و نوادر", value: 3 },
        ],
      },
      { name: "عن المركز", link: { name: "about" }, index: 8 },
      { name: "إتصل بنا", link: { name: "CallUs" }, index: 9 },
    ],
    links: {
      telegramLink: null,
      youtubeLink: null,
      facebookLink: null,
      tweitterLink: null,
      emailLink: null,
    },
    exportSort: null,
    DmSort: null,
    NjfSort: null,
    counter: 0,
    baseUrl: "https://api.altusi.iq/",
    NewsID: null,
    currentPage: null,
    lengthPage: null,
    NavigationDrawers: false,
    snackbar: { state: false, type: true, text: null },
    rootTrack: [
      {
        text: "الــرئـيســية",
        disabled: false,
        href: { name: "home" },
      },
    ],
    csModel: {
      title: null,
      imagePath: null,
      textEditor: null,
      date: null,
      youtub: [],
      gallery: [],
    },
    dmId:
      localStorage.dmId === "object"
        ? localStorage.dmId.value
        : localStorage.dmId,
    csId:
      localStorage.csId === "object"
        ? localStorage.csId.value
        : localStorage.csId,
    subId:
      localStorage.subId === "object"
        ? localStorage.subId.value
        : localStorage.subId,
    njfId:
      localStorage.njfId === "object"
        ? localStorage.njfId.value
        : localStorage.njfId,
    MagazineId:
      typeof localStorage.MagazineId === "object"
        ? localStorage.MagazineId.value
        : localStorage.MagazineId,
    njfLibraryId:
      localStorage.njfLibraryId === "object"
        ? localStorage.njfLibraryId.value
        : localStorage.njfLibraryId,
    searchDialog: false,
    subjects: [],
  }),
  // optional getters
  getters: {
    // getters receive the state as first parameter
    doubleCount: (state) => state.counter,
    page: (state) => state.currentPage,
    exportSortNum: (state) => state.exportSort,
    dialogSearcher: (state) => state.searchDialog,
  },
  actions: {
    searchAction(val) {
      this.searchDialog = val;
      // console.log(val);
    },
    sortChange(from, to) {
      if (from === 2) {
        this.exportSort = to;
      }
      if (from === 6) {
        this.DmSort = to;
      }
      if (from === 7) {
        this.NjfSort = to;
      }
      // console.log(`From ${from} to ${to}`);
    },
    getCS(id, response) {
      this.csId = id;
      // let arr = response.data.gallery;
      // console.log(typeof arr);
      // this.gallery= imgs;
      // this.youtub = videos;
      this.csModel.title = response.data.title;
      this.csModel.date = response.data.date;
      this.csModel.imagePath = response.data.imagePath;
      this.csModel.textEditor = response.data.textEditor;
      // this.csModel.gallery = response.data.gallery;
      this.csModel.youtub = response.data.youtub;
      // console.log(
      //   `youtub = ${ this.csModel.youtub.link == null} --- gallery = ${
      //     this.csModel.gallery
      //   }`
      // );
      // console.log(response.data.gallery);
      // if (Array.isArray(response.data.youtub)) {
      //   let arr = [];
      //   response.data.youtub.forEach((link) => {
      //     arr.push(link);
      //   });
      //   this.csModel.youtub = arr;
      // } else {
      //   this.csModel.youtub = [];
      // }
      // if (Array.isArray(response.data.gallery)) {
      //   let arr = [];
      //   response.data.gallery.forEach((link) => {
      //     console.log(link);
      //     arr.push(link);
      //   });
      //   this.csModel.gallery = arr;
      // } else {
      //   this.csModel.gallery = [];
      // }
      // console.log(id, this.csModel);
    },
    routeChange(list) {
      // console.log(list);
      // console.log(this.rootTrack);
      this.rootTrack = list;
      // console.log("after route change" , this.rootTrack);
    },
    snackbarAction(text, type) {
      let timeout = 0;
      if (this.snackbar.state === true) {
        console.log("Is On");
        this.snackbar.state = false;
        timeout = 5000;
      }
      setTimeout(() => {
        this.snackbar.state = true;
        this.snackbar.text = text;
        this.snackbar.type = type;
      }, timeout);
    },
  },
});
